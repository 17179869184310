<template>
  <div class="p-p-3">
    <i class="pi pi-circle-on hf1 orange p-d-inline"></i>
    <div class="hf1 orange p-d-inline p-ml-3">About Us</div>
    <div class="card2 white p-shadow-4 p-mt-3 p-p-3" style="color: white">
      <!-- <div>
        Welcome to BogorPools, Thank you for introducing you to one og the most
        popular sites in Indonesia. Since Opening in 2021, we have been
        committed to providing our players with a lifetime experience by
        providing exciting products, excellent custom care and the highest level
        of integrity.
      </div>
      <br/>
      <div>
        BogorPools.com is operated under strict supervision and is an authorized
        international representative for the chinese lottery game.
      </div>
      <div>
        BogorPools.com has the right to sell Bogor Lottery products.
      </div>
      <br/>
       -->
      <p class="ql-align-justify">
        <span style="color: rgb(0, 0, 0)">Welcome to </span
        ><strong style="color: rgb(0, 0, 0)">Bogorpools.com,&nbsp;</strong
        ><span style="color: rgb(0, 0, 0)"
          >Thank you for introducing you to one of the most popular sites in </span
        ><strong style="color: rgb(0, 0, 0)"
          >Special Administrative Region of the People`s
          <em>Chinese</em>&nbsp;Federation</strong
        ><span style="color: rgb(0, 0, 0)"
          >. Since opening in 2019, we have been committed to providing our
          players with a lifetime experience by providing exciting products,
          excellent customer care and the highest level of integrity.</span
        >
      </p>
      <p class="ql-align-justify"><br /></p>
      <p class="ql-align-justify">
        <em style="color: rgb(0, 0, 0)">Bogorpools.com</em
        ><span style="color: rgb(0, 0, 0)">
          is operated under strict government supervision and is an authorized
          international representative for the&nbsp;Chinese&nbsp;lottery game. </span
        ><em style="color: rgb(0, 0, 0)">Bogorpools.com</em
        ><span style="color: rgb(0, 0, 0)"
          >&nbsp;has the right to sell&nbsp;Bogor
          Lottery&nbsp;products.</span
        >
      </p>
      <p class="ql-align-justify"><br /></p>
      <p class="ql-align-justify">
        <span style="color: rgb(0, 0, 0)"
          >Superior customer care, VIP services and player clubs: </span
        ><em style="color: rgb(0, 0, 255)"
          >"Your time is understandable when you want to have value, hassle or
          problem-free fun."</em
        ><em style="color: rgb(128, 128, 128)">&nbsp;</em
        ><span style="color: rgb(0, 0, 0)"
          >So we implemented a seven-star customer care team to help you 24/7.
          In addition, regular players are assigned a dedicated host who takes
          care of you, provides great games and bonuses, and the player club is
          second to none.</span
        >
      </p>
      <p class="ql-align-justify"><br /></p>
      <p class="ql-align-justify">
        <span style="color: rgb(0, 0, 0)"
          >Thank you for visiting our website.</span
        >
      </p>
      <p class="ql-align-justify">
        <span style="color: rgb(0, 0, 0)"
          >If you have any questions or comments regarding our products or
          services, please send an e-mail to </span
        ><a
          href="mailto:info@Bogorpools.com"
          rel="noopener noreferrer"
          target="_blank"
          style="color: rgb(0, 0, 255)"
          >info@Bogorpools.com</a
        ><span style="color: rgb(0, 0, 0)">.</span>
      </p>
      <p><br /></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>